import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { contactUs } from "../../actions/contact";
import "./style.css";

const Contact = (props) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");
  const [error, setError] = useState(false);

  function renderFlower() {
    return (
      <div className="rowFlower" id="contact">
        <div className="threeCol">
          <div className="colInner">
            <div className="wrapper"></div>
          </div>
        </div>
        <div className="threeCol">
          <div className="colInner">
            <div className="wrapper"></div>
          </div>
        </div>
        <div className="threeCol">
          <div className="colInner">
            <div className="wrapper">
              <div className="holder">
                <div className="holderItem">
                  <div>
                    <div className="itemContent">
                      <div style={{ textAlign: "center" }}>
                        <figure>
                          <div className="imgWrapper">
                            <img width="239" height="224" src={null} alt=" " />
                          </div>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderForm() {
    return (
      <div style={{ textAlign: "center" }}>
        <div className="formGridSection">
          <div className="rowFluid">
            <div className="colContainer">
              <div className="colInner">
                <div>
                  <div className="titleHolderContainer">
                    <div className="titleItemCell">
                      <div style={{ padding: "60px 0 0" }}>
                        <div className="titleHolder">
                          <h2>NON PERDIAMOCI DI VISTA</h2>
                          <h3>Contattaci per maggiori informazioni</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "67px" }}>
                    <div className="rowGrid">
                      {result !== "" ? (
                        <div
                          style={{
                            backgroundColor: error ? "darkred" : "#398f14",
                            color: "#FFF",
                            margin: "50px",
                            padding: "30px 0",
                            fontWeight: 600,
                            fontSize: "20px",
                          }}
                        >
                          {result}{" "}
                        </div>
                      ) : (
                        <form>
                          <div
                            style={{
                              display: "flex",
                              padding: "30px 10px",
                              flexWrap: "wrap",
                            }}
                          >
                            <div className="formItem">
                              <input
                                type="text"
                                name="name"
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Nome"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                              />
                            </div>
                            <div className="formItem">
                              <input
                                type="text"
                                name="surname"
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Cognome"
                                value={surname}
                                onChange={(e) => setSurname(e.target.value)}
                              />
                            </div>
                            <div className="formItem">
                              <input
                                type="text"
                                name="email"
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </div>
                            <div className="formItem">
                              <input
                                type="text"
                                name="phone"
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Telefono"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                              />
                            </div>
                            {/*
                            <div className="formItem">
                              <input
                                type="date"
                                name="date"
                                size="40"
                                aria-required="true"
                                aria-invalid="false"
                                placeholder="Data evento"
                                value={date}
                                onChange={(e) => setDate(e.target.value)}
                              />
                            </div>
                            */}
                            {/*
                            <div className="formItem">
                              <select
                                placeholder="TIPOLOGIA EVENTO"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                              >
                                <option value="MATRIMONIO">MATRIMONIO</option>
                                <option value="BATTESIMO">BATTESIMO</option>
                                <option value="COMPLEANNO">COMPLEANNO</option>
                                <option value="COMUNIONE">COMUNIONE</option>
                                <option value="CONGRESSO">CONGRESSO</option>
                                <option value="FESTA">FESTA PRIVATA</option>
                                <option value="ALTRO">ALTRO</option>
                              </select>
                            </div>
                            */}
                            <div
                              style={{ padding: "10px 20px", width: "100%" }}
                            >
                              <textarea
                                onChange={(e) => setMessage(e.target.value)}
                                name="message"
                                cols="40"
                                rows="6"
                                aria-invalid="false"
                                placeholder="Messaggio"
                              >
                                {message}
                              </textarea>
                            </div>
                          </div>
                        </form>
                      )}
                    </div>

                    {result === "" ? (
                      <div
                        className="sendUsBtn"
                        onClick={(e) =>
                          contactUs({
                            name: name,
                            surname: surname,
                            email: email,
                            message: message,
                            phone: phone,
                          }).then((result) => {
                            setResult(result.message);
                          })
                        }
                      >
                        INVIA
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      {renderFlower()}
      {renderForm()}
    </React.Fragment>
  );
};

export default Contact;
