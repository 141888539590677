import axios from "axios";

export function contactUs(data) {
  let request = {
    method: "POST",
    url: process.env.PUBLIC_URL + "/php/contact.php",
    data: data,
  };

  return new Promise((resolve, reject) => {
    return axios(request)
      .then((result) => {
        resolve({
          error: false,
          message: "Grazie per averci contattato",
        });
      })
      .catch((response) => {
        reject({
          error: true,
          message:
            "Si è verificato un errore, prova a contattarci a info@scuolamaniinpasta.it",
        });
      });
  });
}
