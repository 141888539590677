import React from "react";
import "./style.css";
import MarcoManziImg from "../../assets/img/istruttori/marcoManzi.png";
import AlexPirasImg from "../../assets/img/istruttori/alexpirascagliari.png";
import AntonelloScatorchiaImg from "../../assets/img/istruttori/antonelloscatorchia.png";
import AntonioSabbareseImg from "../../assets/img/istruttori/antoniosabbaresecalabria.png";
import DomenicoIaconoImg from "../../assets/img/istruttori/domenicoiaconoischia.png";
import FabioFonteImg from "../../assets/img/istruttori/fabiofonte.png";
import FabrizioAscoliImg from "../../assets/img/istruttori/fabrizioascoli.png";
import FabrizioSattaImg from "../../assets/img/istruttori/fabriziosatta.png";
import FrancescoPolitoImg from "../../assets/img/istruttori/francescopolito.png";
import GiuseppeLeopardiImg from "../../assets/img/istruttori/giuseppeleopardi.png";
import GiuseppePesareImg from "../../assets/img/istruttori/giuseppepesare.png";
import GiuseppePiccoloImg from "../../assets/img/istruttori/giuseppepiccolo.png";
import LucaDamatoImg from "../../assets/img/istruttori/lucadamato.png";
import MattiaFaraoneImg from "../../assets/img/istruttori/mattiafaraone.png";
import PierluigiCarboniImg from "../../assets/img/istruttori/pierluigicarboni.png";
import PierluigiPoliceImg from "../../assets/img/istruttori/pierluigipolice.png";

class Istruttori extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          currentIndex: 0
        };
        this.istruttori= [
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Marco Manzi (premiato 3 spicchi Gambero Rosso) nella sede di Firenze, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Marco Manzi",
                tel: "3293979719",
                 urlImage: MarcoManziImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Alex Piras nella sede di Cagliari, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Alex Piras",
                tel: "-",
                urlImage: AlexPirasImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Antonello Scatorchia, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Antonello Scatorchia",
                tel: "-",
                urlImage: AntonelloScatorchiaImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Antonio Sabbarese nella sede in Calabria, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Antonio sabbarese",
                tel: "-",
                urlImage: AntonioSabbareseImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Domenico Iacono nella sede di Ischia, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Domenico Iacono",
                tel: "3355279701",
                urlImage: DomenicoIaconoImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Fabio Fonte Nuova nella sede di Roma, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Fabio Fonte Nuova",
                tel: "-",
                urlImage: FabioFonteImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Fabrizio nella sede di Ascoli, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Fabrizio",
                tel: "-",
                urlImage: FabrizioAscoliImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Fabrizio Satta nella sede di Sassari, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Fabrizio Satta",
                tel: "-",
                urlImage: FabrizioSattaImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Francesco Polito nella sede della Sicilia, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Francesco Polito",
                tel: "-",
                urlImage: FrancescoPolitoImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Giuseppe Leopardi nella sede di Aversa, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Giuseppe Leopardi",
                tel: "-",
                urlImage: GiuseppeLeopardiImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Giuseppe Pesare nella sede di Firenze, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Giuseppe Pesare",
                tel: "-",
                urlImage: GiuseppePesareImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Giuseppe Piccolo nella sede di Aversa, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Giuseppe Piccolo",
                tel: "-",
                urlImage: GiuseppePiccoloImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Luca d'Amato nella sede di Orvieto, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Luca d'Amato",
                tel: "-",
                urlImage: LucaDamatoImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Mattia Faraone nella sede di Poggio Picenze (Aquila), è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Mattia Faraone",
                tel: "-",
                urlImage: MattiaFaraoneImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Pierluigi Carboni nella sede di Porto Torres, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Pierluigi Carboni",
                tel: "-",
                urlImage: PierluigiCarboniImg
            },
            {
                desc: "Il corso professionale di pizzaiolo effettuato dall’Istruttore Pierluigi Police nella sede di Arezzo, è un corso teorico-pratico della durata minima di 40 ore. Ad ogni corsista sarà fornito il kit costituito da: borsa,giacca,t-shirt,grembiule,libro,penna,bloc-notes e termometro. Al termine del corso verranno testate le capacità acquisite con un test di valutazione sia teorico che pratico; al seguito di tale esame verrà rilasciato il diploma da pizzaiolo e l’attestato di specializzazione in Pizza Napoletana",
                name: "Pierluigi Police",
                tel: "-",
                urlImage: PierluigiPoliceImg
            }
        ];
      }

    renderSchedaIstruttore(desc, name, tel, urlImage){
        return (
            <div>
            <div className="image" style={{backgroundImage: `url(${urlImage})`}} />
            <div className="contentSchedaIstruttore">
                <div className="descrizioneScheda">
                    {desc}
                    <br/><br/>
                 Info: {tel}
                </div>
                <div className="nameIstruttore">{name}</div>
            </div>
        </div>
        );
    }

    render() {
      return (
          <React.Fragment>
          <section className="section_istruttori">
            <div className="elemento-shape elemento-shape-top" data-negative="false">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path className="elementor-shape-fill" d="M0,6V0h1000v100L0,6z" />
                </svg>
            </div>

            <div className="elementContainer" style={{padding: "70px 0 400px"}}>
                  <div style={{textAlign: "center", width: "100%"}}>
                      <span className="sectionTitle" style={{color: "#5097ea"}}>I NOSTRI ISTRUTTORI</span>
                      <div style={{color: "#FFF"}}>
                          <h2>Conosci gli istruttori qualificati</h2>
                          <span style={{lineHeight: "25px"}}>I professionisti pronti a soddisfare ogni vostra sete di conoscenza nel mondo culinario</span>
                      </div>
                  </div>

              </div>
          </section>

          <section className="section_schedaIstruttori">
              <div className="elementContainer">
              {
                this.renderSchedaIstruttore(this.istruttori[this.state.currentIndex].desc, this.istruttori[this.state.currentIndex].name, this.istruttori[this.state.currentIndex].tel, this.istruttori[this.state.currentIndex].urlImage)
            }

              </div>

          </section>
          <nav class="nav_arrows">
              {
                this.state.currentIndex > 0 ?

                    <button class="prev" aria-label="Prev"  onClick={e => this.setState({currentIndex: this.state.currentIndex - 1})}>
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.494 31.494" style={{enableBackground: "new 0 0 31.494 31.494", width: "30px", height: "30px"}} xmlSpace="preserve">
                            <path style={{fill: "#1E201D"}} d="M10.273,5.009c0.444-0.444,1.143-0.444,1.587,0c0.429,0.429,0.429,1.143,0,1.571l-8.047,8.047h26.554  c0.619,0,1.127,0.492,1.127,1.111c0,0.619-0.508,1.127-1.127,1.127H3.813l8.047,8.032c0.429,0.444,0.429,1.159,0,1.587  c-0.444,0.444-1.143,0.444-1.587,0l-9.952-9.952c-0.429-0.429-0.429-1.143,0-1.571L10.273,5.009z" />
                        </svg>
                    </button>
                : null
                }
          <div class="counter">
              <span>{this.state.currentIndex + 1}</span>
              <span>{this.istruttori.length}</span>
              </div>
              {
                this.state.currentIndex < this.istruttori.length-1 ?
              <button class="next" aria-label="Next" onClick={e => this.setState({currentIndex: this.state.currentIndex + 1})}>
                 <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 31.49 31.49" style={{enableBackground: "new 0 0 31.49 31.49", width: "30px", height: "30px"}} xmlSpace="preserve">
                     <path style={{fill: "#1E201D"}} d="M21.205,5.007c-0.429-0.444-1.143-0.444-1.587,0c-0.429,0.429-0.429,1.143,0,1.571l8.047,8.047H1.111  C0.492,14.626,0,15.118,0,15.737c0,0.619,0.492,1.127,1.111,1.127h26.554l-8.047,8.032c-0.429,0.444-0.429,1.159,0,1.587  c0.444,0.444,1.159,0.444,1.587,0l9.952-9.952c0.444-0.429,0.444-1.143,0-1.571L21.205,5.007z" />
                </svg>
                </button>
                : null
                }
            </nav>
          </React.Fragment>
      );
    }
}

export default Istruttori;