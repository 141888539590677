import React from "react";
import "./style.css";
import Typed from "react-typed";

class Copertina extends React.Component {
    render() {
      return (
          <section className="copertina_section">
              <div className="element-background-overlay"></div>
                <div className="element-container element-column-gap-default">
                    <div>

                        <br />
                        <Typed
                            loop
                            typeSpeed={40}
                            backSpeed={80}
                            strings={["Vuoi diventare pizzaiolo? ",
                            "Sei gia pizzaiolo e vuoi specializzarti in pizza napoletana? ",
                            "Vuoi semplicemente imparare a fare la pizza in casa per i tuoi amici?",
                            "Scuola mani in pasta ti aiuta offrendoti corsi adatti a te!"]}
                            smartBackspace
                            shuffle={false}
                            backDelay={1}
                            fadeOut={false}
                            fadeOutDelay={200}
                            loopCount={0}
                            showCursor
                            cursorChar="|"
                            style={{color: "#FFF", fontSize: "40px"}}
                        />
                    </div>
                </div>
          </section>
      );
    }
}

export default Copertina;