import React from "react";
import Contatti from "./contact";
import Copertina from "./copertina";
// import Corsi from "./corsi";
import Header from "./header/index";
import Istruttori from "./istruttori";
import OfferDesc from "./offerDesc";

class Home extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="page_content_wrapper">
          <Copertina />
          <OfferDesc />
          <Istruttori />
          {/*
                  <Corsi />
                  */}
          <Contatti />
        </div>
      </div>
    );
  }
}

export default Home;
