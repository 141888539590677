import React from "react";
import PizzaPreview from "../../assets/img/pizzaPreview.jpg";
import PasticceriaImg from "../../assets/img/pasticceria.jpg";
import CucinaImg from "../../assets/img/cucina.jpg";
import RosticceriaImg from "../../assets/img/rosticceria.jpg";
import "./style.css";

class OfferDesc extends React.Component {
    render() {
      return (
          <section className="offerDesc_section">
              <div className="elementContainer">
                  <div style={{textAlign: "center", width: "100%"}}>
                      <span className="sectionTitle">COSA OFFRIAMO</span>
                      <div>
                          <h2>Tanti corsi professionali o amatoriali nel campo della ristorazione</h2>
                          <span style={{lineHeight: "25px"}}>La scuola mani in pasta nasce con lo scopo di formare le figure professionali del pizzaiolo e del panettiere, ma anche di organizzare corsi amatoriali di pizzeria, panificazione e pasticceria.
I corsi teorico-pratici sono tenuti da istruttori specializzati, in diverse sedi in Italia e all'estero.
Ai nostri allievi, inoltre, sarà fornito il materiale completo per la formazione, durante la quale potranno far uso di attrezzature professionali.
Con la Scuola Mani in Pasta i nostri allievi avranno l'opportunità di essere inseriti nel mondo del lavoro con proposte sempre nuove.</span>
                      </div>
                  </div>

              </div>
              <div className="elementContainer" style={{padding: "50px 0"}}>
                  <div className="offerImgContainer">
                  <img src={PizzaPreview}  alt="" />
                  <div style={{letterSpacing: "2px", color: "#222", borderRadius: 0, margin: "-25px 0", textAlign: "center", fontWeight: 500, textTransform: "uppercase"}}><div style={{display: "inline-block", backgroundColor: "#FFF", padding: "16px"}}>Pizzeria</div></div>
                     </div>

                     <div className="offerImgContainer">
                  <img src={PasticceriaImg} alt="" />
                  <div style={{letterSpacing: "2px", color: "#222", borderRadius: 0, margin: "-25px 0", textAlign: "center", fontWeight: 500, textTransform: "uppercase"}}><div style={{display: "inline-block", backgroundColor: "#FFF", padding: "16px"}}>Pasticceria</div></div>
                     </div>

                     <div className="offerImgContainer">
                  <img src={RosticceriaImg} alt="" />
                  <div style={{letterSpacing: "2px", color: "#222", borderRadius: 0, margin: "-25px 0", textAlign: "center", fontWeight: 500, textTransform: "uppercase"}}><div style={{display: "inline-block", backgroundColor: "#FFF", padding: "16px"}}>Rosticceria</div></div>
                     </div>

                     <div className="offerImgContainer">
                  <img src={CucinaImg}  alt="" />
                  <div style={{letterSpacing: "2px", color: "#222", borderRadius: 0, margin: "-25px 0", textAlign: "center", fontWeight: 500, textTransform: "uppercase"}}><div style={{display: "inline-block", backgroundColor: "#FFF", padding: "16px"}}>Cucina</div></div>
                     </div>
                     </div>

          </section>
      );
    }
}

export default OfferDesc;