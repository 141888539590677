import React from "react";
import Logo from "../../assets/img/logoMedium.png";
import { FaFacebookF, FaInstagram } from "react-icons/fa";

import "./style.css";

class Header extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (
      window.scrollY === 0 &&
      document.getElementById("element_header") !== null
    ) {
      console.log("c");
      document.getElementById("element_header").className =
        "header_style_wrapper visible";
    } else if (
      window.scrollY !== 0 &&
      document.getElementById("element_header") !== null
    ) {
      console.log("ds");
      document.getElementById("element_header").className =
        "header_style_wrapper scroll";
    }
  }
  render() {
    return (
      <div id="element_header" className="header_style_wrapper visible">
        <div class="element-container element-column-gap-no">
          <div className="element-row">
            <div className="element-col-37" style={{ textAlign: "left" }}></div>
            <div className="element-col-center" style={{ textAlign: "center" }}>
              <img
                src={Logo}
                style={{
                  width: "60px",
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
                alt="logo scuola"
              />
              <div className="signature" style={{ display: "inline-block" }}>
                <span
                  style={{
                    lineHeight: "28px",
                    display: "inline-block",
                    fontSize: "28px",
                    wordBreak: "break-word",
                  }}
                >
                  Scuola mani in pasta
                </span>
              </div>
            </div>
            <div className="element-col-30" style={{ textAlign: "right" }}>
              <div className="social_icons">
                <a className="facebookIcon" href="">
                  <FaFacebookF />
                </a>
                <a className="instagramIcon" href="">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
